import React from 'react'

function MenuFooter(props) {

  return (
    <div>
      
    </div>
  )
}

export default MenuFooter
